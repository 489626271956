import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import FAQ from '../../components/common/FAQ'
import PricingPlans from '../../components/pricing/PricingPlans'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const ClimateRiskSustainabilityPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Navigate hazard risk and build resilience against an increasing cadence of natural disasters with analyses on climate, community, and market dynamics',
        title: 'Atlas for Climate Risk & Sustainability',
        type: 'website'
      }}
      title={'Atlas for Climate Risk & Sustainability'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Climate Risk & Sustainability'}
            description={
              'Navigate hazard risk and build resilience against an increasing cadence of natural disasters with analyses on climate, community, and market dynamics'
            }
            video="https://cdn.atlas.co/landing-pages/climate-risk-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>
              Navigate climate risk and drive critical resilience investments
            </h2>
            <p>
              Climate change is a global challenge that requires a coordinated
              and strategic response. Atlas helps you understand the risks and
              opportunities associated with climate change.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={
              'https://cdn.atlas.co/landing-pages/environmental-management.mp4'
            }
            icon={{ src: '/icons/tree.svg', color: '#FFF4E7' }}
          >
            <h3>Environmental management</h3>
            <p>
              Monitoring environmental shifts and predicting future changes
              using spatial data is essential to minimize environmental
              degradation.
            </p>
            <p>
              Whether analyzing air pollution, managing disasters, tracking
              forest fires, responding to oil spills, or handling wastewater
              management, GIS solutions provide powerful tools to protect our
              planet.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/bird-density.mp4'}
            reverse
            icon={{ src: '/icons/butterfly.svg', color: '#CDE4E1' }}
          >
            <h3>Biodiversity monitoring</h3>
            <p>
              Predicting potential habitats for endangered species with limited
              known ranges and identifying biodiversity hotspots using spatial
              context is crucial for preserving our world’s biodiversity.
            </p>
            <p>
              GIS platforms enable targeted surveys and monitoring schemes,
              allowing for precise data collection on habitat distribution and
              change measurement.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/flood-risk.mp4'}
            icon={{
              src: '/icons/warning.svg',
              color: '#FFBF6E'
            }}
          >
            <h3>Risk and proximity evaluation</h3>
            <p>
              Granular data is essential for fully understanding asset
              assessments at the local level.
            </p>
            <p>
              In Atlas, you can easily import local data to evaluate risks and
              to assess the proximity of assets to potential hazards.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default ClimateRiskSustainabilityPage
